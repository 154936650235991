module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"artTeryxx_WDD","short_name":"artTeryxx","start_url":"/","background_color":"#f9f7fa","theme_color":"#f9f7fa","display":"minimal-ui","icon":"src/assets/images/aBa_logo_oRANGJE_icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
